<template>
  <div class="side-bar">
    <div class="scrollbar">
      <el-menu :default-active="sideIndex" class="el-menu-vertical-demo" @select="sideSelect" router>
        <el-menu-item v-for="item in sideList" active-class="actived" :key="item.index" :index="item.url">{{ item.name }}</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  name: "UserSide",
  data() {
    return {
      sideList: [
        {
          index: 1,
          name: "个人信息",
          url: "/user",
        },
        {
          index: 2,
          name: "我的会员",
          url: "/members",
        },
        {
          index: 3,
          name: "我的认证",
          url: "/my-certification",
        },
        // {
        //   index: 4,
        //   name: "职业认证",
        //   url: "/professional-certification",
        // },
        // {
        //   index: 5,
        //   name: "我的发布",
        //   url: "/publish",
        // },
        {
          index: 6,
          name: "我的参与",
          url: "/participation",
        },
        {
          index: 7,
          name: "我的订单",
          url: "/order",
        },
        // {
        //   index: 8,
        //   name: "我的评价",
        //   url: "/evaluate",
        // },
        // {
        //   index: 9,
        //   name: "数据中心",
        //   url: "/data-center",
        // },
      ],
      isCollapse: true,
      sideIndex: "/user",
    };
  },
  computed: {
    sideindex() {
      return this.$store.state.sideactive;
    },
  },
  watch: {
    sideindex: function (newV, oldV) {
      this.sideIndex = newV;
    },
  },
  mounted() {
    this.sideIndex = this.sideindex;
  },
  methods: {
    sideSelect(key, keyPath) {
      this.sideIndex = key;
      this.$store.dispatch("saveSideActive", key);
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  position: relative;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 7px 0px rgba(50, 50, 50, 0.11);
  overflow: hidden;
  ::v-deep .el-menu-item {
    height: 46px;
    line-height: 46px;
  }
  .el-menu{
    border-right: 0;
  }
  .el-menu-item.is-active {
    &:before {
      position: absolute;
      left: 55px;
      top: 14px;
      content: "";
      width: 3px;
      height: 16px;
      background: #1e50ae;
      display: inline-block;
    }
  }
}
.el-menu-item{
  padding-left: 0!important;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  height: calc(100% - 60px);
  text-align: center;
}
.actived {
  background: red;
}
</style>
