<template>
  <div class="user-container">
    <user-header />
    <div class="user-main">
      <user-side />
      <router-view></router-view>
    </div>

    <Loading v-if="LOADING" />
  </div>
</template>

<script>
import UserHeader from "@/components/UserHeader";
import UserSide from "@/components/UserSide";
import Loading from "@/components/Loading";
import { mapState } from "vuex";
export default {
  name: "user-home",
  components: {
    UserHeader,
    UserSide,
    Loading,
  },
  computed: {
    ...mapState(["LOADING"]),
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.user-container {
  position: relative;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  .user-main {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
  }
}
</style>
